import MainImg from '../../assets/images/newsCellCardPartner/MainImg.webp'
import Img1 from '../../assets/images/newsCellCardPartner/SectionImg1.webp'
import Img2 from '../../assets/images/newsCellCardPartner/SectionImg2.webp'
import Img3 from '../../assets/images/newsCellCardPartner/SectionImg3.webp'


export const postDetails={
    img:MainImg,
    title:"Cellcard Partners With Telehealth Platform MyCLNQ Global, And Digital Payments Leader Visa To Launch Cambodia’s 1st E-Health Service",
    views:"19K",
    date:"MAY 10, 2023",
    text:<>
 
                                        <b>The Covid-19 pandemic has put a spotlight on preventive healthcare, repositioning health services within the priorities of the government, private institutions, communities, and consumers.</b>
                                        <p>This growth in interest in healthcare presents opportunities for further developments in the category, especially for innovation providers to address the needs of a digital-reliant generation and the digital economy. In Cambodia, access to quality healthcare is a key priority outlined under the government’s Universal Health Coverage (UHC) initiative, which guides the development of medical facilities, healthcare workers’ licensing, and healthcare systems.</p>
                                        <p>Today, Cambodia’s homegrown mobile network operator, Cellcard, demonstrates how it shares the vision for health access equality with the launch of Cellcard’s E-Health Service – a first-to-market initiative.In partnership with Cambodia-Singapore HealthTech venture, MyCLNQ, and the world’s leader in digital payments, Visa, the Cellcard E-Health Service aims to close the access gap to quality health care with a comprehensive telehealth platform that everyone can access through their mobile phone.</p>
                                        <p>“We pride ourselves on being an enabler for our customers’ digital lifestyles. The addition of an E-Health Service to our roster of Value-Added Services allows us to provide for more holistic lifestyle needs. Launching Cambodia’s first mobile-based E-Health Service opens a whole new world of opportunities and conveniences. We’ve partnered with the best to deliver a unique digital healthcare ecosystem, allowing our customers priority access to healthcare facilities, virtual consultations with healthcare experts, telemedicine and mobile inpatient care, among other things. It’s an insightful application of technology, and we’re excited to make this service available to everyone.” – Cellcard CEO, Simon Perkins.</p>
                                        <p>Cellcard’s E-Health Services will have a phased roll-out which starts with its E-Health Services being bundled with Cellcard’s Tourist SIM. The bundle is developed in preparation for the tourist influx for the 32nd SEA Games and the 12th ASEAN Para Games in May and June.</p>
                                        <p>Cellcard’s Tourist+ package will be available in two variants: Standard and Pro. The bundles will be offered with an introductory subscription promotion from April 28 to May 31, providing FREE SIM/Regular SIM with $5 Top-Up at $12 [UP: $15] for Standard, and at $17 [UP: $20] for Pro, as well as a 10% discount on all subscription bundle for payment via Visa</p>
                                        <p>“We are very excited to partner with Cellcard to launch Cambodia’s first digital e-health ecosystem. Working together with Cellcard and Visa, there are several initiatives in development to service various consumer segments. E-Health MyCLNQ aims to solve healthcare pain points across Asian markets, starting from Cambodia and the Mekong region.” – e-Health MyCLNQ CEO Socheat Bin.</p>
                                        <p>“We are thrilled to see the tourism sector gearing up after the pandemic. However, health is still a top priority for individuals and families, and it becomes more important when you travel overseas together with loved ones. Our partnership with Cellcard and Visa enables hassle-free tourist care along with a data package which enables basic healthcare at your fingertips and medicine deliveries at your doorstep.” – e-Health MyCLNQ Chief Business Officer Anwar Rafique.</p>
                                        <p>The partnership with Visa provides additional benefits to subscribers of Tourist+. Payment of subscriptions through Visa offers an extra 10% off the subscription, as well as a 10% discount on medical bills.</p>
                                        <p>“Our partnership with Cellcard and e-Health MyCLNQ to launch Cambodia’s first Tourist+ supports the rebound of Cambodia’s tourism sector. Based on VisaNet data, we see an encouraging trajectory back to pre-pandemic travel flows. However, how consumers travel has fundamentally changed. For example, 78% of consumers in Asia Pacific say that they are cautious about health and hygiene when selecting destinations for leisure travel. By offering cardholders a trusted, affordable and digital-first product to address their health and safety concerns, this partnership aims to promote the personal and financial safety and security tourists can expect when visiting the Kingdom. Cellcard’s Tourist+ is an important innovation, and we are proud to partner with Cellcard and eHealth MyCLNQ for this initiative.” – Ms. Ivana Tranchini, Visa Country Manager for Cambodia.</p>
                                        <b>ABOUT E-HEALTH MYCLNQ</b>
                                        <p>e-Health MyCLNQ Global CO., LTD., (e-Health MyCLNQ), is a joint venture HealthTech company between Cambodia Pethyoeung and Singapore MyCLNQ Health for the purpose of becoming a leading telehealth platform to enable healthcare services to be more accessible and affordable at a customer’s fingertips. The company provides holistic one-stop AI digital healthcare solution with value-based healthcare methodology.For more information on these offers, please visit: <a href="https://e-healthmyclnq.com/" class="colorRed" target="_blank" rel="noopener noreferrer">www.e-healthmyclnq.com</a></p>
                                    
                                    
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]
import React,{ useState,useEffect} from 'react'

const LoadingIcon = () => {
    const [show,setShow]=useState(true);
    useEffect(() => {
      
        const timeoutId = setTimeout(() => {
          setShow(false);
        }, 500); 

        return () => clearTimeout(timeoutId);
      }, []);
  

  return (
    <div className={`fixed left-0 h-full w-full text-center  ${show? 'z-[99999]': ' z-[-1]'}  durationn-[0.9s] before:absolute 
    before:left-0 before:top-0 before:h-full ${show? 'before:w-[60%]': 'before:w-0 '}  before:z-[-1] before:bg-reddish 
    before:duration-500 after:absolute after:left-auto after:right-0 after:top-0  
    after:h-full   ${show? 'after:w-[60%]': 'after:w-0 '} after:z-[-1] after:bg-reddish after:duration-500`}>
           <div className={`absolute h-[80px] w-[80px] rounded-[5%]
             inline-block left-0 right-0 my-0 mx-auto top-[45%] duration-500 ${show?'visible':'hidden opacity-0'}   ` }>
      
                <div className="absolute border-[4px] border-[#ffffff] border-l-transparent w-full
                 h-full rounded-[50%] animate-animate-outerloader border-b-0"></div>
                <div className="absolute border-[4px] border-[#ffffff] rounded-[50%] w-[72%] h-[72%] left-[calc(40%-21px)]
                top-[calc(40%-21px)] border-r-0 border-t-transparent animate-animate-innerloader
                "></div>

                <div className="absolute right-0 left-[38px] top-[50%] transform translate-y-[-50%] scale-[1.5]"> 
                    <svg width="16px" height="12px" className='w-[16px] h-[12px] overflow-hidden  fill-none '>
                        <polyline id="back" points="1 6 4 6 6 11 10 1 12 6 15 6" className='stroke-[#ffffff] fill-none stroke-2 stroke-line'>
                        <line x1="1" y1="3" x2="5" y2="3" stroke="black" strokeLinecap="round" className='stroke-white stroke-2' />
                        <path d="M1,1 h4 M1,3 h4 M1,5 h4" stroke="pink" strokeWidth="0.025" className='stroke' />
                        </polyline>                     
                        <polyline id="front" points="1 6 4 6 6 11 10 1 12 6 15 6" 
                        className=' stroke-reddish stroke-dasharray-[12,36] stroke-dashoffset-[48] animate-animate-stroke'>
                              <line x1="0" y1="5" x2="30" y2="5" strokeDasharray="12 36"   strokeDashoffset="48" className=' animate-animate-stroke'/>
                        </polyline>
                    </svg>
                </div>
            </div>
        </div>
  )
}

export default LoadingIcon
import React, {useState ,useCallback ,useContext}from 'react'
import { IoMdArrowDroprightCircle } from "react-icons/io";
import Button from './Button';
import { IoFlaskSharp } from "react-icons/io5";
import { Link,useNavigate } from 'react-router-dom';
import DataContext from '../../hooks/DataContext';

const Table2 = ( {table2Data ,isActive ,editHeight}) => {
    const [isOpen, setIsOpen] = useState({
        1: false,
        2: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
      });
      const {openModal} =useContext(DataContext);
      const navigate=useNavigate();
      
	const handleOpenModal = (number) => {
		openModal(number);
		navigate('/healthCareBooking');
		console.log(number)
	
		// Navigate to the /healthcare-details page
		
	  };
   

    const toggleList = useCallback((index) => {
        setIsOpen((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
      }, [setIsOpen]);
  return (
    <>
    <div className="flex-[0_0_100%] max-w-[100%] lg:flex-[0_0_31%] lg:max-w-[31%] relative w-full min-h-[1px] px-[15px] ">
                <div className={`bg-[#fff] border border-[#ddd]	 text-center relative
                 overflow-hidden my-[15px] pt-[45px] pr-[35px] pb-[30px] pl-[35px] ${isActive ? `${editHeight}` : 'lg:min-h-[50rem]'}`}>
                    {/* <!-- Table Head --> */}
                    <div className=" text-center">
                        <div className=" flex items-center justify-center">
                        <IoFlaskSharp className='text-[65px] text-reddish font-normal'/>
                        </div>
                        <h4 className=" text-navfont text-[21px] mt-[30px] mb-[15px] 
                      font-medium leading-[1.2]">PROTECT</h4>
                        <div className="price">
                            <p className="text-[36px] font-semibold text-reddish">INR 700<span  className=' inline-block text-sm font-normal
                           text-textAsh ml-[8px] leading-[24px]'>64 Tests</span></p>
                        </div>	
                    </div>
                    {/* <!-- Table List --> */}
                    <ul className="py-[10px] text-left mt-[30px]">
                        {table2Data.map(({id,category,details})=>
                <React.Fragment key={id}>
                         <li className="table-list"> <IoMdArrowDroprightCircle 
                         className={`text-[18px] mr-[10px] absolute 
right-[-2px] h-[24px] w-[24px] leading-[22px] text-center text-reddish
bg-[#fff] rounded-full pl-[1px] duration-500 ${ isOpen[id]? 'rotate-90' :'rotate-0'}`}
 onClick={()=> toggleList(id)} />{category} </li>
            <div className={ `sub-list duration-500 ${ isOpen[id]? 'visible max-h-[1000px] duration-500 mb-[1rem]':'hidden mb-[1rem] max-h-[0px]'}
              `}>
                {details.map((detail, index) => (
                  <p key={index}>{detail}</p>
                ))}
            </div>
                </React.Fragment>
            )} 
                        
                    </ul>
                    {isActive&&<div className=" w-full flex justify-end text-reddish text-sm font-normal absolute bottom-[98px] left-[-38px]">
								<Link  to={'/healthCarePackage'}>View more</Link>
                              </div>}
                              <div className={`mt-[25px]  ${isActive?'lg:pt-[2.1rem]':'lg:pt-[4.6rem]'} `}>
            <Button text={'Book Now'} width={'w-full'} handleClick={()=>handleOpenModal(3)}/>
        </div>
                    {/* <!-- Table Bottom --> */}
                </div>
            </div>
    </>
  )
}

export default Table2
import React from 'react'
import Nav from '../Components/Nav'
import ClinicDetailsMain from '../Components/ClinicDetailsMain'
import Footer from '../Components/Footer'
import { slides ,doctorDetails,clinicDetails ,isActive} from '../Components/Lists/drRaziqueEntClinic'
import GoToTop from '../Components/GoToTop'

const DrRaziqueENTClinic = () => {
  return (
    <>
    <Nav/>
    <ClinicDetailsMain
      slides={slides} 
      doctorDetails={doctorDetails} 
      clinicDetails={clinicDetails}
      isActive={isActive}
    
    />
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default DrRaziqueENTClinic
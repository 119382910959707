import React from 'react'
import Nav from '../Components/Nav'
import ArticleMain from '../Components/ArticleMain'
import Footer from '../Components/Footer'
import { recentPost, postDetails} from "../Components/Lists/articleEye"
import GoToTop from '../Components/GoToTop'

const ArticleEye = () => {
  return (
    <>
    <Nav/>
    <ArticleMain recentPost={recentPost} postDetails={postDetails} height1={'lg:h-[2700px]'} height2={ 'md:h-[2569px]'}/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default ArticleEye
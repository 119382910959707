import slide1 from '../../assets/images/clinicDetails/satyaClinic/dental-room.webp'
import slide2 from '../../assets/images/clinicDetails/satyaClinic/women-appointment.webp'


export const slides = [
    {
      url: slide1,
    },
    {
      url: slide2,
    },
  ];
  export const isActive=false;
  export const doctorDetails={
      name:'Dr. D R Abhishek ',
      speciality:'Dental & Cosmologist',
      phoneNo:'8700556054'
  }

  export const clinicDetails={
   heading:'Welcome to Satya Skin Clinic - Your Trusted Partner in Dermatology',
   docName:'Dr. D R Abhishek',
   qualification:'BDS (Germany) Cosmologist & Trichologist',
   speciality:'Dental & Cosmologist',
   phoneNum:'8700556054',
   email:'sarfrazsaifi1991@gmail.com',
   exp:'8 years',
   languages:'Hindi and English',
   clinicAddress: 'A 113-24, Durga Chowk, Bhalswa Dairy, Delhi 110042',
   clinicTimings: 'Everyday 10:00 AM - 02:00 PM, 05:00 PM - 08:30 PM',
   doctorBookingNum:1,
   details:<>
       <p className='mt-[20px]  text-[14px] text-[#757575] m-0 leading-[24px] font-normal'>At Satya Skin Clinic, we prioritize your skin health and well-being. We understand that convenience matters, and that's why we offer seamless audio and video consultation services to bring expert dermatological care right to your doorstep, no matter where you are in India.</p>
                                  
                                    <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Why Choose Satya Skin Clinic:</p>
                            <ol className="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                        <li><b>Virtual Consultations:</b> Experience personalized consultations with our experienced dermatologists from the comfort of your home. Our audio and video consultation services ensure accessibility and convenience.</li>
                                        <li><b>Expert Dermatologists:</b> Our team comprises highly skilled and qualified dermatologists committed to providing accurate diagnoses and effective treatment plans tailored to your unique skin needs.</li>
                                        <li><b>Comprehensive Skin Care:</b> From common skin conditions to specialized treatments, we offer a wide range of dermatological services to address your concerns. Whether it's acne, aging, or allergies, we've got you covered.</li>
                                        <li><b>Patient-Centric Approach:</b> At Satya Skin Clinic, we believe in a patient-centric approach. Our goal is to make your dermatological journey as smooth as possible, offering guidance and support at every step</li>
                                        <li><b>State-of-the-Art Technology:</b> We stay at the forefront of dermatological advancements, incorporating state-of-the-art technology to ensure accurate diagnostics and effective treatments.</li>
                                    </ol>
                                    
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>How It Works:
                                    </p><ol className="ml-3 list-decimal  text-[14px] text-[#757575] m-0 leading-[24px] font-normal">
                                        <li><b>Schedule Your Appointment:</b> Easily book your audio or video consultation through our user-friendly online platform.</li>
                                        <li><b>Connect with Our Experts:</b> Join the virtual consultation at your scheduled time. Our dermatologists will discuss your concerns, provide advice, and guide you through the next steps.</li>
                                        <li><b>Prescription and Follow-Up:</b> Receive personalized treatment plans and prescriptions digitally. Follow up with our team as needed for a continued partnership in your skin health</li>
                                    </ol>
                               
                                <p className='mt-[40px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '><b>Your Skin, Our Priority:</b></p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Satya Skin Clinic is more than just a dermatology clinic; it's a commitment to your skin's well-being. Trust us to provide expert care, leveraging the latest technology to bring the best of dermatology to your doorstep.</p>
                                <p className=' mt-[20px] text-[14px] text-[#757575] m-0 leading-[24px] font-normal '>Schedule your virtual consultation today and embark on a journey to healthier, happier skin with Satya Skin Clinic.</p>
   </>
  }
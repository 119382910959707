import { RiArrowDropDownLine } from "react-icons/ri";

export const topHeaderLinks = [
    {
      id: 1,
      link: "WORLDWIDE",
      path:"https://myclnq.co/worldwide/"
    },
    {
      id: 2,
      link: "CONTACT US",
       path:"/Contact"
    },
    {
      id: 3,
      link: "FAQ",
      path:"/FAQ"
    },
    {
      id: 4,
      link: "GET APP",
      path:"/GetApp"
    },
  ];
export  const bottomHeaderLinks = [
    {
      id: 1,
      link: "About Us",
      dropDown:<RiArrowDropDownLine className='inline-block text-2xl ml-[1px] leading-none '/>, 
      dropDownList:true,
      lineBottom:false,
      dropDownListItems:[
        {
          newId:1,
          newLink:"About Company",
          path:"/about"
        },
        {
          newId:2,
          newLink:"Management Team",
          path:"/management"
        }
      ]
    },
    {
      id: 2,
      link: 'Products & Services',
      dropDown:<RiArrowDropDownLine className='inline  text-2xl ml-[1px] leading-none' />,
      dropDownList:true,
      lineBottom:false,
      dropDownListItems:[
        {
          newId:1,
          newLink:"Doctors for you",
          path:"/DoctorsForYou"
        },
        {
          newId:2,
          newLink:"Care Wagon",
          path:"/CareWagon"
        },
        {
          newId:3,
          newLink:"Care Giver",
          path:"/CareGiver"
        },
      ]
    },
    {
      id: 3,
      link: 'HealthCare Packages',
      path:"/healthCarePackage",
      lineBottom:true
    },
    {
      id: 4,
      link: 'News & Articles',
      path:"/newsArticles",
      lineBottom:true
    },
    {
      id: 5,
      link: 'Events',
      path:"/events",
      lineBottom:true
    },
  ];
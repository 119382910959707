import MainImg from '../../assets/images/articleAllergy/MainImg.webp'
import Img1 from '../../assets/images/articleAllergy/SectionImg1.webp'
import Img2 from '../../assets/images/articleAllergy/SectionImg2.webp'
import Img3 from '../../assets/images/articleAllergy/SectionImg3.webp'

export const postDetails={
    img:MainImg,
    title:"Allergies And Why You Can Develop New Ones Later In Life",
    views:"15K",
    date:"OCTOBER 11, 2023",
    text:<>
    <p >Most people still 
    hold the misconception that allergies are something that only 
    happens during our childhood and adolescent years. As such, those lucky enough not to 
    develop allergies during this time may believe they will be allergy-free for the rest of their life. 
    Unfortunately, this is far from the truth, as anyone can develop allergies during their later years in life, 
    even for things they were not allergic to before.</p>    
   <p >These adult-onset allergies can arise at any time and may stem from various causes, like eating a
    childhood favourite snack. According to healthcare providers, such allergies can pop up out of nowhere at any time during your life. 
    Read on as we go over why this happens and the steps to manage any allergies you may develop during adulthood.</p>
    <b>A brief overview of allergies</b>
    <p >Allergies are different from intolerance or food sensitivity in that they 
    cause a response or reaction from the body’s immune system rather than causing the body to have difficulty 
    digesting a certain food chemical. Allergens or foreign substances are what cause this immune system reaction, 
    but while they are typically harmless, exposure to them may prompt the immune system of some people to become 
    overly sensitive and release chemicals like histamine.</p>
    <p  >These chemicals are what drive the many allergy symptoms we know today, such as 
    sneezing, stuffy or runny nose, watery or itchy eyes, hives, diarrhoea, nausea, and vomiting. Many food allergies, 
    like eggs and milk, are generally outgrown in late childhood, while others, such as peanut allergies, may last for much longer.
    However, oral allergy syndrome is different in that it can lead to new allergies appearing during adolescence and adulthood.</p>
    <p >The leading cause of this is that some fruits and vegetables have a similar protein structure
     similar to that found in protein, leading to cross reactivity. For example, some fruits and vegetables can be associated 
     with grass pollen (melons and oranges), while others are more similar to birch tree pollen (cherries, apples, and pears).
      So, if someone is sensitive to the latter and eats an apple, their body may mistake it for pollen due to the protein 
      similarities. Thankfully, the allergic reaction that occurs typically subsides on its own.</p>
    <p>Another factor that may lead to adult-onset allergies is when a person has had a severe allergic reaction 
    during their childhood. Experiencing even just a single episode of symptoms increases one’s risk of developing allergies later in life 
    upon re-exposure to the same allergen at higher concentrations.</p>
    <p>These links are easy in some cases and represent what is called the atopic march. Children who have skin 
    conditions or food allergies are more likely to develop symptoms of seasonal allergies as they get older and then fade again, 
    only to return in their 20s to 40s when exposed to their allergy triggers. Some of these potential adult allergy triggers include:</p>
    <b>How to manage adult-onset allergies</b>
    <p>The good news is that adult-onset allergies go away with time and usually fade once again as you reach your 
    50s since your immune function gets reduced as you grow older and causes a less severe response to allergens. That being said, 
    if you are concerned about the allergy symptoms you are experiencing now and want to know how to best manage them, here are 
    three tips to find relief:</p>
    <b>1. Determine what exactly you are allergic to</b>
    <p>This entails paying attention to your exposures and when your symptoms arise. 
    Alternatively, you could expedite this process and achieve more precise findings by consulting with a virtual doctor
     in Singapore about allergy testing. This test involves scratching your skin with various allergens in small traces to 
     observe if your body is sensitive to them. Once you know what you are allergic to, the next step is to work with your 
     healthcare provider to come up with a plan on how to best reduce your exposure to allergens.</p>
    <b>2. Optimise your medications</b>
    <p>There are many medicines that can help reduce congestion and inflammation in the nose, such as antihistamines 
    or steroid nose sprays. Other options include eye drops for relieving dry and itchy eyes and allergy pills for alleviating symptoms.</p>
    <b>3. Consider getting allergy shots</b>
    <p>If you have chronic allergic rhinitis, you could consider getting injections of the allergens that trigger your symptoms to help your body gradually tolerate exposures. Do note that this is a slow process that can take anywhere from three to five years, but getting your body accustomed to the allergens and virtually putting a stop to your symptoms may be well worth it.</p>
    <b>Conclusion</b>
    <p>Developing allergies is not exclusive to our younger years, as it can also occur during adulthood. To prevent
     allergy symptoms from affecting your daily life, consider booking an online medical consultation and speaking to a doctor as soon as possible. 
     With the MYCLNQ app, you can connect with many certified medical professionals and find the one best suited to help with your specific health concern. To learn more about our other services, such as private ambulance transport and supervised ART testing, don’t hesitate to contact us today</p>
    <p>Reference: <a href="https://health.clevelandclinic.org/can-develop-allergies-later-in-life/" 
    target="_blank" rel="noopener noreferrer">Cleveland Clinic</a></p>
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]
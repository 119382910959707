import MainImg from '../../assets/images/newsAngMedical/MainImg.webp'
import Img1 from '../../assets/images/newsAngMedical/SectionImg1.webp'
import Img2 from '../../assets/images/newsAngMedical/SectionImg2.webp'
import Img3 from '../../assets/images/newsAngMedical/SectionImg3.webp'


export const postDetails={
    img:MainImg,
    title:"MyCLNQ Health Opens New Clinic “A&G Medical” In Singapore",
    views:"23K",
    date:"AUGUST 30, 2023",
    text:<>
   <p>MyCLNQ Health is opening a brand new clinic in the center of Singapore. Located on Orchard Rd, 
    this new medical center takes the name “A&amp;G Medical” and offers bespoke health screening packages. 
    It aims at providing exceptional and personalized care that goes beyond generic packages.</p>    
    <p>A&amp;G Medical address your concerns comprehensively with packages tailored to your age, gender and health history.
         Their services extend from a full body check-up to specific
         areas of interest depending on your preferences while always keeping in mind cost and convenience.
          In addition to this A&amp;G Medical provides guidance through alternative packages.</p>
    <p>Their range of services is wide with Dermatology, Mental Wellness, Allergy testings, weight 
        management and many more. Please find the complete list of their services 
        <a class="colorRed" href="https://angmedical.co/service.html" target="_blank" rel="noopener noreferrer">here</a>.</p>
    <p>You can also find the list of all packages with complete description and costs at
         <a href="https://angmedical.co/signaturewellness.html" className="text-reddish" 
         target="_blank" rel="noopener noreferrer">A&amp;G Medical / Screening</a>.</p>
                                    
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]
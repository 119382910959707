import MainImg from '../../assets/images/newsMinister/MainImg.webp'
import Img1 from '../../assets/images/newsMinister/SectionImg1.webp'
import Img2 from '../../assets/images/newsMinister/SectionImg2.webp'
import Img3 from '../../assets/images/newsMinister/SectionImg3.webp'


export const postDetails={
    img:MainImg,
    title:"Minister Announces 15 Health Innovators Chosen For Regulatory Sandbox",
    views:"29K",
    date:"MAY 31, 2023",
    text:<>
 
                                        <p>Health Minister Budi Gunadi Sadikin delivering his remarks through a video conference on Health Innovation Day 2023, held from Jakarta on Tuesday (May 30, 2023). (ANTARA/Sean Filo Muhamad/uyu)</p>    
                                        <p>“…Thus Health Technology Transformation Can Continue To Be Expedited To Provide More Equitable And Quality Health Services For The Community.”
                                            Jakarta (ANTARA) – Health Minister Budi Gunadi Sadikin announced the names of 15 telehealth innovation developers who have been selected to join the Health Ministry’s regulatory sandbox program.</p>
                                        <p>He made the announcement via video conferencing on Health Innovation Day 2023 here on Tuesday.

                                            He said that his party launched the regulatory sandbox program in April 2023. The program aims to evaluate digital innovations applied in the business process, business model, technology, and governance of telehealth service providers.</p>
                                        <p>The evaluation was conducted by the Health Ministry in collaboration with experts.

                                            The minister said that the 15 telehealth developers announced are eligible to join the program with supervised status.</p>
                                        <p>“The implementation of this (regulatory sandbox) program is (now) extended to a more diverse digital health innovation business model, after previously being applied to (health services on) malaria (treatment),” he disclosed.</p>
                                        <p>Registrations for the regulatory sandbox program will also be opened for other health service clusters in the future, he added.</p>
                                        <p>Hence, other digital health service developers could take part in the program later to directly cooperate with and be fostered by the ministry to improve their digital innovations, he stated.</p>
                                        <p>The 15 selected developers are Riliv, Medic+, Klinik Simas Sehat, Good Doctor, Naluri, <b>MyCLNQ Sehat</b>, Lifepack, Alodokter, Halodoc, Sehati TeleCTG, Getwell, FitHappy, Cexup, SIRKA, and SehatQ.

                                            Furthermore, the 10 best innovations in the 2023 Health Innovation Sprint Accelerator program — an incubation program for startups and digital health innovation developers — were also revealed at the event.</p>
                                        <p>They were CoFilm Antimicrobial Coating, Fatkilla, Gizi Nusantara, Healthpro.id, Neurabot, Nexmedis, PathGen, RADScan, Sepsis 360, and Vinera.

                                            In addition, the ministry announced the 7 best participants in the 2023 Fight for Access Accelerator Indonesia program that empowers female-led health service providers to innovate in increasing health access.</p>    
                                        <p>The selected participants included PT Neura Integrasi Solusi, Pedis Care Nursing Center, PT Cipta Medika Informasi, PT Generasi Sehat Cerdas, PT Kita Keluarga Indonesia, PT Hati Anak Bangsa, and PT Riliv Psikologi Indonesia.</p>
                                        <p>“Hopefully, this (Health Innovation Day) event can continue to be held in the following years, thus health technology transformation can continue to be expedited to provide more equitable and quality health services for the community,” the minister added.</p>
                                    
    </>
}


export const recentPost=[
    {
        id:1,
        img:Img1,
        heading:"Change In Eye Colour And Why It Warrants Medical Attention",
        date:"Nov 9, 2023",
        link:"/articleEye",
        border:true
    },
    {
        id:2,
        img:Img2,
        heading:"Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date:"Oct 26, 2023",
        link:"/articleMigrane",
        border:true
    },
    {
        id:3,
        img:Img3,
        heading:"5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date:"Oct 20, 2023",
        link:"/articleRemedies",
        border:false
    },
    ]
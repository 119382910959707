import React from 'react';
import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import GoToTop from '../Components/GoToTop';
import Events from '../Components/Events';
import Event1 from '../Components/Event1';
import Event2 from '../Components/Event2';
import Event3 from '../Components/Event3';

function Event() {
  return (
    <>
    <Nav/>
    <Events/>
    <Event1/>
    <Event2/>
    <Event3/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default Event
import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import MissingMain from '../Components/MissingMain'
import GoToTop from '../Components/GoToTop'

const MissingPage = () => {
  return (
    <>
    <Nav/>
    <MissingMain/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default MissingPage
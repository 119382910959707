import React, { useEffect, useState } from 'react'
import logo from "../assets/images/myclnq.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCaretForward } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { bottomHeaderLinks, topHeaderLinks } from "./Lists/navList"
import { Link, NavLink, useLocation } from 'react-router-dom';
import LoadingIcon from './LoadingIcon';
import ProFeatures from './ProFeatures';
import Button from './subComponents/Button';
import { HashLink } from 'react-router-hash-link'


const Nav = () => {
  const [nav, setNav] = useState(false);
  const [show, setShow] = useState(false);
  const [dropDown1, setDropDown1] = useState(false);
  const [dropDown2, setDropDown2] = useState(false);
  const location = useLocation();
  const [isGroupHovered, setIsGroupHovered] = useState(false);
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const transistionNavBar = () => {
    if (window.scrollY > 50) {
      setShow(true)
    }
    else {
      setShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", transistionNavBar);
    return () => window.removeEventListener("scroll", transistionNavBar)
  }, [])


  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <LoadingIcon />
      <ProFeatures />
      <header className='header bg-[#fff]   relative w-full'>          {/*removed max-h-[129px]*/}
        { !isMediumScreen && <div className={`top-header min-h-[55px] text-navfont mx-[-15px] text-[14px] 
    py-[15px] border-b-[1px]  !border-navborder `}>
          <div className='flex flex-col max-h-[25px] items-center  
      lg:flex-row lg:justify-between lg:!mx-[3rem]  text-[14px]'>
            <ul className=' flex  items-center justify-between pl-[10px] text-center gap-2 lg:gap-5'>
              {topHeaderLinks.map(({ id, link, path }) => (
                <li
                  key={id}
                  className=" hover:text-reddish  duration-200">
                  {id === 1 ? (
                    <a href={path} target="_blank" rel="noopener noreferrer">
                      {link}
                    </a>
                  ) : (
                    <Link to={path}>{link}</Link>
                  )}
                </li>
              ))}
            </ul>
            <ul >
              <li className='flex items-center justify-center'>
                <IoMdMail className=' text-reddish' />
                <a href="mailto:support@myclnq.co.in"
                  className=' pl-2 text-[14px] hover:text-reddish  duration-200'>support@myclnq.co.in</a></li>
            </ul>
          </div>
        </div>}
        <div className={` bottom-header   flex justify-around items-center md:w-full min-h-[60px]
     ${show ? 'fixed top-0  w-full  bg-white z-[9999999] shadow-[0px_0px_13px_#00000054] duration-500  animate-nav-scroll' : ''}`} >
          <nav className={` flex  items-center !mx-[2rem] w-full justify-between ${show && 'relative'} `}>
            <div className='logo md:h-[56px] md:mt-[18px] md:flex-[0_0_25%] md:max-w-[25%]   '>
              <Link to='/'>
                <img src={logo} alt='logo' className=' max-w-full max-h-[2.6rem]' loading='lazy' />
              </Link>
            </div>
            <ul className="hidden lg:flex   items-center md:flex-[0_0_58.333333%]  md:max-w-[58.333333%]  flex-wrap relative ">
              {bottomHeaderLinks.map(({ id, link, dropDown, dropDownList, dropDownListItems, path, lineBottom }) => (
                <li
                  key={id}
                  className=" text-navfont text-sm font-medium cursor-pointer capitalize 
              duration-200 relative group hover:text-reddish mr-[15px] float-left   "
                  onMouseEnter={() => setIsGroupHovered(true)}
                  onMouseLeave={() => setIsGroupHovered(false)}
                >

                  <NavLink to={path} className={`px-[12px] max-xl:pl-0 py-[25px] flex justify-center items-center relative ${location.pathname === path ? 'text-reddish' : ''
                    }`}
                  >
                    {link}
                    {dropDown && <span>{dropDown}</span>}
                  </NavLink>

                  <div className={`absolute w-0 group-hover:w-full
             group-hover:right-0 rounded-tl-[15px]
             rounded-tr-[15px]  left-0 right-[143px] 
              h-[4px] bg-reddish transition-all duration-500 ${lineBottom ? 'bottom-[-2px]' : ' bottom-0'} ${location.pathname === path ? 'w-full' : 'w-0'
                    }`}></div>
                  {dropDownList &&
                    <ul className={`dropdown absolute transition-all duration-500 opacity-0  
                   z-[-200]  left-0   ${isGroupHovered && 'group-hover:z-[200] group-hover:translate-y-0 group-hover:opacity-100'}
                    top-[74px] bg-[#fff] min-w-[220px] 
                   border-l-[3px] border-reddish shadow-[0px_3px_5px_#3333334d]  
                   ${show && (isGroupHovered ? 'block' : 'hidden')}
                   `}

                    >     {/*added z-[-200] group-hover:z-[200] to resolve*/}
                      {dropDownListItems.map(({ newId, newLink, path }) => (
                        <HashLink smooth to={path} key={newId}><li className='  border-b border-dashed border-b-[#eee] py-3 px-[15px] 
                        text-[#666] font-normal capitalize hover:text-reddish bg-transparent duration-500 text-[14px]' >
                          {newLink}</li></HashLink>
                      ))}
                    </ul>}
                </li>
              ))}
            </ul>
            <div className="hidden lg:flex mr-[20px] mt-3 flex-[0_0_16.666667%]  max-w-[16.666667%] items-center justify-end  ">
              <HashLink smooth to="/bookAppointment"><Button text={'Book Appointment'} /></HashLink>

            </div>
            <div className='cursor-pointer pr-4 z-10  lg:hidden'>
              <GiHamburgerMenu className='text-reddish' onClick={() => setNav(!nav)} />
            </div>

          </nav>

        </div>
        {nav && <div className={` mobile-nav bg-white  md:px-[100px] md:py-[30px] p-[30px] pt-[10px]
   lg:hidden duration-500 ${!show && 'relative'} z-[99] ${show && 'top-[45px] md:top-[69px] w-full fixed duration-500'}`}>
          <div className={`top-header min-h-[63px] text-navfont mx-[-15px] text-[14px] 
    py-[15px] border-b-[1px]  !border-navborder mb-[12px]`}>
            <div className='flex flex-col max-h-[25px] items-center  
      lg:flex-row lg:justify-between lg:!mx-[3rem]  text-[14px]'>
              <ul className=' flex  items-center justify-between pl-[10px] text-center gap-2 lg:gap-5'>
                {topHeaderLinks.map(({ id, link, path }) => (
                  <li
                    key={id}
                    className=" hover:text-reddish  duration-200 mx-[4px] mb-[5px]">
                    {id === 1 ? (
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    ) : (
                      <Link to={path}>{link}</Link>
                    )}
                  </li>
                ))}
              </ul>
              <ul  >
                <li className='flex items-center justify-center '>
                  <IoMdMail className=' text-reddish' />
                  <a href="mailto:support@myclnq.co.in"
                    className='pl-2 text-[14px] hover:text-reddish  duration-200'>support@myclnq.co.in</a></li>
              </ul>
            </div>
          </div>

          <div>
            <ul className='duration-500'>
              <li className='py-[5px]  px-[10px] bg-transparent  font-normal text-[#2C2D3F]
                          hover:text-reddish duration-500 flex items-center'  onClick={() => setDropDown1(!dropDown1)}>About Us <span className='inline-block pl-3'>
                  <IoCaretForward className={`duration-500 ${dropDown1 ? 'rotate-90' : 'rotate-0'}`}
                    onClick={() => setDropDown1(!dropDown1)} /></span></li>
              {dropDown1 && <ul className="py-[12px] px-[30px] bg-transparent  font-normal text-[#2C2D3F]
                        hover:text-reddish duration-500 flex-col items-center" >
                <li className='py-[5px]'><Link to='/about'>About Company</Link></li>
                <li className='py-[5px]'><Link to='/management'>Management Team</Link></li>
              </ul>}

              <li className="py-[5px]  px-[10px] bg-transparent  font-normal text-[#2C2D3F]
                       hover:text-reddish duration-500 flex items-center " onClick={() => setDropDown2(!dropDown2)} >

                Products &amp; Services <span className='inline-block pl-3'>
                  <IoCaretForward className={`duration-500 ${dropDown2 ? 'rotate-90' : 'rotate-0'}`}
                    onClick={() => setDropDown2(!dropDown2)} /></span></li>
              {dropDown2 && <ul className="py-[12px] px-[30px]  bg-transparent  font-normal text-[#2C2D3F]
                         hover:text-reddish duration-500 ">
                <li className='py-[5px]' ><Link to='/DoctorsForYou'>Doctors for you</Link></li>
                <li className='py-[5px]'><Link to='/CareWagon'>Care Wagon</Link></li>
                <li className='py-[5px]'><Link to='/CareGiver'>Care Giver</Link></li>
              </ul>}


              <li className=' py-[5px]  px-[10px] bg-transparent flex items-center text-[#2C2D3F]
                       hover:text-reddish duration-500'><Link to='/healthCarePackage' className=''>HealthCare Packages</Link></li>
              <li className=' py-[5px]  px-[10px] bg-transparent flex items-center font-normal text-[#2C2D3F]
                        hover:text-reddish duration-500'><Link to='/newsArticles' >News &amp; Articles</Link></li>
              <li className=' py-[5px]  px-[10px] bg-transparent flex items-center font-normal text-[#2C2D3F]
                        hover:text-reddish duration-500'><Link to='/events' >Events</Link></li>
            </ul>
          </div>
        </div>}
      </header>
    </>
  )
}

export default Nav
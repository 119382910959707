import React, { createContext,useState } from 'react'

const DataContext = createContext({})

export const DataContextProvider=({children})=>{

    const [modalState, setModalState] = useState({
        packageName: "",
        packagePrice: "",
        packageNameIN: "",
      });

      const [doctorPackageState, setDoctorPackageState] = useState({
        packageName: "",
        packagePrice: "",
        packageNameIN: "",
      });
    
      const setModalData = (data) => {
        setModalState(data);
      };
      const setDoctorModal = (data) => {
        setDoctorPackageState(data)
      };
    const openModal = (number) => {
        let packageName = "";
        let packagePrice = "";
        let packageNameIN = "";
      
        switch (number) {
          case 1:
            packageName = "MyCLNQ-Privilege Package";
            packagePrice = 'Care Amount :- INR 2200/-';
            packageNameIN = 'MyCLNQ-Privilege Package. Care Amount :- INR 2200/-';
            break;
          case 2:
            packageName = "MyCLNQ-Care Package";
            packagePrice = 'Care Amount :- INR 400/-';
            packageNameIN = 'MyCLNQ-Care Package. Care Amount :- INR 400/-';
            break;
          case 3:
            packageName = "MyCLNQ-Protect Package";
            packagePrice = 'Care Amount :- INR 700/-';
            packageNameIN = 'MyCLNQ-Protect Package. Care Amount :- INR 700/-';
            break;
          default:
            packageName = "";
        }
        const modalState = {
          packageName,
          packagePrice,
          packageNameIN,
        };
      
        setModalData(modalState);
      
        return modalState;
      };
      const handleDoctorDetails= (number) => {
        let packageName = "";
        let packagePrice = "";
        let packageNameIN = "";
      
        switch (number) {
          case 1:
            packageName = "Booking for Satya Skin Clinic - Doctor Appointment";
            packagePrice = 'Care Amount :- INR 200/-';
            packageNameIN = 'Booking for Satya Skin Clinic - Doctor Appointment. Care Amount :- INR 200/-';
            break;
          case 2:
            packageName = "Booking for Mind Vision Neurology Clinic - Doctor Appointment";
            packagePrice = 'Care Amount :- INR 1500/-';
            packageNameIN = 'Booking for Mind Vision Neurology Clinic - Doctor Appointment. Care Amount :- INR 1500/-';
            break;
          case 3:
            packageName = "Booking for Medi Club India - Doctor Appointment";
            packagePrice = 'Care Amount :- INR 700/-';
            packageNameIN = 'Booking for Medi Club India - Doctor Appointment. Care Amount :- INR 700/-';
            break;
            case 4:
              packageName = "Booking for Dr. Razique ENT Clinic - Doctor Appointment";
              packagePrice = 'Care Amount :- INR 500/-';
              packageNameIN = 'Booking for Dr. Razique ENT Clinic  - Doctor Appointment. Care Amount :- INR 500/-';
              break;
          default:
            packageName = "";
        }
        const modalState = {
          packageName,
          packagePrice,
          packageNameIN,
        };
      
        setDoctorModal(modalState);
      
        return modalState;
      };

      

     
    return(
        <DataContext.Provider value={{modalState, setModalData,openModal ,doctorPackageState,handleDoctorDetails,setDoctorModal}}
        >
           {children}
        </DataContext.Provider>
    )
}

export default DataContext
import React from 'react'
import { Link } from 'react-router-dom'
import avatar from '../assets/images/articleAllergy/avatar.webp'
import { FaCalendarAlt } from "react-icons/fa";
import { LuClock9 } from "react-icons/lu";
import { FaEye } from "react-icons/fa";

const NewsMain = ({recentPost ,postDetails}) => {
    const {img,title,views,date,text}=postDetails
  return (
    <section className="bg-[#F9F9F9] lg:pt-[60px] lg:pb-[90px] lg:h-[2153px] 
    md:h-[3275px] md:py-[70px] h-[5149px] py-[50px] ">
    <div >
      <div className=" flex lg:flex-row-reverse  md:flex-col lg:justify-center items-center
        md:justify-between md:gap-14 gap-3 lg:gap-0 flex-col ">
        <div className="px-[15px]  lg:w-[1139px] md:w-[763px] w-[375px] flex justify-center items-center ">
          <div className="flex md:mx-[-15px] lg:h-[2465px]  md:h-[2534px]  px-[15px] h-[4519px]  lg:w-[1139px] md:w-[763px] w-[375px]
           md:justify-normal md:items-baseline justify-center items-center  ">
              <div className="md:mt-[30px] lg:mt-0 md:p-[30px]  p-[15px] lg:h-[2003px]  md:h-[2534px] h-[4519px]
               rounded-[8px] shadow-[0px_0px_15px_#ede7f6] bg-[#fff] lg:w-[1139px] md:w-[763px] w-[345px]">
                <div className="text-center md:h-[308px] flex justify-center items-center">
                  <img src={img}  alt="MainImg" className='md:h-[308px]' loading='lazy'/>
                </div>
                <h1 className="md:text-[25px] text-xl my-[20px] text-[#252525] font-semibold hover:text-reddish duration-500 leading-tight">
                {title}</h1>                   
                <div className=" overflow-hidden border-t-[1px] border-t-[#ebebeb]  border-b-[1px] border-b-[#ebebeb] w-full py-[10px] mb-[15px] ">
                  <div className=" float-left w-[375px]">
                    <span className="  text-[#2C2D3F] font-normal mr-[10px] text-sm"><a href="dndjn" className='text-[#2C2D3F] font-normal text-sm leading-[24px]'>
                      <img src={avatar} alt="avatar" className=' w-[45px] h-[45px] rounded-full  mr-3 inline-block' loading='lazy'/>BY THE TEAM AT MYCLNQ</a></span>
                    <span className=" text-[#2C2D3F] font-normal text-sm mt-[12px]  ">
                      <LuClock9 className='mr-[5px] text-sm inline-block text-reddish'/>{date}</span>
                  </div>
                  <div className=" float-right mt-[10px]">
                    <span className=" text-[#2C2D3F] font-normal text-sm leading-[24px]">
                      <FaEye className='mr-[5px] text-sm inline-block text-reddish'/>{views} Views</span>
                  </div>
                </div>
                <div className=" font-normal text-sm text-[#888] leading-[24px] text-left article">{text}</div>
              </div>
          </div>
        </div>
        <div className=" px-[15px]  lg:h-[2465px]	 md:h-[488px] h-[472px] mt-[30px] lg:mt-0 lg:w-[380px] md:w-[768px]"> 
            <div className=" lg:p-[40px] md:p-[20px] p-[20px] bg-[#fff] rounded-[8px] md:h-[458px] lg:h-[475px] 
             h-[472px] shadow-[0px_0px_15px_#0000001a]">
              <h3 className=" relative  text-[18px] font-semibold capitalize mb-[30px] 
              block bg-[#fff] pl-[12px] text-[#2C2D3F] leading-tight
               before:absolute before:left-0 before:bottom-[-1px] before:h-full before:w-[3px] before:bg-reddish">Recent post</h3>
               {recentPost.map(({id,img, heading,date,link ,border})=>
               <div className={`relative ${border&& 'border-b'} ${border&& 'border-b-[#ddd]'}   inline-block py-[17px] md:h-[124px] h-[122px]`} key={id}>
               <div className=" lg:w-[270px] h-0 md:w-[527px]">
                 <img className='object-cover float-left w-[80px] h-[80px] mr-[20px]' src={img} alt="SectionImg" loading='lazy'/>
               </div>
               <div className=" pl-[100px] lg:h-[88px] md:h-[52px]">
                 <h5 className=' leading-[18px]  font-medium text-[#2C2D3F] '><Link to={link}
                  className=' text-[#2C2D3F] font-medium text-sm  block mb-[10px] hover:text-reddish duration-500'>
                   {heading}</Link></h5>
                 <ul>
                   <li className='text-[#888] inline-block mr-[15px] font-normal text-sm leading-[24px]'>
                     <FaCalendarAlt className='inline-block mr-[5px]'/>{date}</li>
                 </ul>
               </div>
             </div>
               )}             
            </div>
          </div>
        </div>
      </div>
  </section>
  )
}

export default NewsMain
import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import ArticleMain from '../Components/ArticleMain'
import { recentPost, postDetails} from "../Components/Lists/articleSleep"
import GoToTop from '../Components/GoToTop'

const ArticleSleep = () => {
  return (
    <>
    <Nav/>
    <ArticleMain  recentPost={recentPost} postDetails={postDetails} 
    height1={'lg:h-[3348px] md:h-[3356px] h-[5080px]'} height2={ 'md:h-[3201px] h-[4470px]'} height3={'h-[4479px]'}/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default ArticleSleep
import { IoHomeSharp } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { SlEmotsmile } from "react-icons/sl";
import { ImTable2 } from "react-icons/im";


export const factsList=[
    {
         id:1,
         icon: <IoHomeSharp className=' h-[70px] duration-500
         w-[70px] text-[28px] text-[#fff]  group-hover:text-reddish  text-center absolute top-[-1px] left-[-1px]  font-normal leading-[67px] p-[20px]'/>,
         counter:"5,000",
         text:'Hospitals & Pharmacy'
    },
    {
        id:2,
        icon: <FaUser className=' h-[70px] duration-500
        w-[70px] text-[28px] text-[#fff] group-hover:text-reddish   text-center absolute top-[-1px] left-[-1px]  font-normal leading-[67px] p-[20px]'/>,
        counter:"4,000",
        text:'Doctors &  Practitioner'
   },
   {
    id:3,
    icon: <SlEmotsmile className=' h-[70px] duration-500
    w-[70px] text-[28px] text-[#fff] group-hover:text-reddish   text-center absolute top-[-1px] left-[-1px]  font-normal leading-[67px] p-[20px]'/>,
    counter:"300,000",
    text:'Lives Served'
   },
    {
    id:4,
    icon: <ImTable2 className=' h-[70px] duration-500
    w-[70px] text-[28px] text-[#fff] group-hover:text-reddish  text-center absolute top-[-1px] left-[-1px]  font-normal leading-[67px] p-[20px]'/>,
    counter:"200,000",
    text:'Waiting Minutes Saved'
    }
]
import React, { useState, useEffect } from 'react'
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import img1 from "../assets/images/Events/BeverlyHills/img1.jpg"
import img2 from "../assets/images/Events/BeverlyHills/img2.jpeg"
import img3 from "../assets/images/Events/BeverlyHills/img3.jpeg"
import img4 from "../assets/images/Events/BeverlyHills/img4.jpeg"
import img5 from "../assets/images/Events/BeverlyHills/img5.jpeg"
import img6 from "../assets/images/Events/BeverlyHills/img6.jpeg"

function Event2() {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);


    const slides = [
        {
            url: img1,
        },
        {
            url: img2,
        },
        {
            url: img3,
        },
        {
            url: img4,
        },
        {
            url: img5,
        },
        {
            url:img6,
        }
    ];


    const prevSlide = () => {

        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };

    const nextSlide = () => {

        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };
    const onAnimationEnd = () => {
        setAnimate(false);
    };

    const transitionStyles = {
        transition: 'background-image 0.5s ease-in-out',
        animation: animate ? 'fadeInOut 0.5s ease-in-out' : 'none',
    };
    const bannerStyle = {
        backgroundImage: `url(${slides[currentIndex].url})`,
        ...transitionStyles,
    };


    const nextSlideWithAnimation = () => {

        setAnimate(true);
        setTimeout(() => {
            nextSlide();
        }, 500);
    };
    useEffect(() => {
        const autoplayTimer = setInterval(() => {
            nextSlideWithAnimation();
        }, 3000);
        return () => clearInterval(autoplayTimer);
        // eslint-disable-next-line
    }, [currentIndex]);



    return (
        <>
            <section className=' md:m-11 m-2 h-[500px]  bg-backgroundAsh ' >
                <div className='flex lg:flex-row flex-col-reverse lg:items-center  lg:justify-center items-stretch justify-stretch w-full h-[500px]'>
                    <div className=' md:w-[50%] w-[100%] lg:h-[500px] h-[560px]' >
                        <div
                            style={bannerStyle}
                            onAnimationEnd={onAnimationEnd}
                            className='md:w-[84%] w-[98%] md:left-[40px] left-[4px]  md:h-[485px] lg:h-[500px] h-[550px] bg-center bg-cover transition-all duration-500 relative rounded-3xl '
                        >
                            {/* Left Arrow */}
                            <div className='absolute  lg:top-[50%] md:top-[409px] top-[50%] left-0 md:left-[7px] -translate-x-0 
        translate-y-[-50%] lg:left-[0.75rem]  rounded-full w-[50px] h-[50px]
          text-white  cursor-pointer leading-[50px] inline-block'>
                                <HiMiniChevronLeft onClick={prevSlide} size={50} />
                            </div>
                            {/* Right Arrow */}
                            <div className=' absolute  lg:top-[50%] md:top-[409px] right-0   top-[50%]  w-[50px] h-[50px]
          -translate-x-0 translate-y-[-50%] md:right-[7px]  text-2xl leading-[50px] rounded-full
        text-white cursor-pointer  inline-block'>
                                <HiMiniChevronRight onClick={nextSlide} size={50} />
                            </div>

                        </div>
                    </div>
                    <div className=' md:w-[50%] w-[100%]  h-[500px] md:mb-0 mb-[165px]'>
                        <div className="lg:mx-[48px] lg:[24px] md:p-8 p-2">
                            <h3 className=" text-reddish  text-[1.75rem] font-poplin leading-9">Health Care Camp At Beverly Hills Society- Geeta Colony  East Delhi</h3>
                            <p className=" my-4 text-[#2C2D3F] font-poplin text-[1rem]">MyCLNQ India orchestrated a impactful healthcare camp at Beverly Hills Society in Geeta Colony, East Delhi. The event proved fruitful, attending to the medical needs of over 50 patients, with an impressive 30+ patients enrolling in healthcare packages. This highlighted the community's demand for accessible healthcare solutions. <br /> MyCLNQ India's dedication to improving healthcare accessibility was evident through this initiative, resonating positively within the Geeta Colony community. By serving a substantial number of patients and facilitating enrollment in healthcare packages, the camp not only addressed immediate health concerns but also laid a foundation for long-term wellness in the area. The success of the event underscores MyCLNQ India's commitment to promoting health and well-being in communities across Delhi.</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Event2
import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import FAQMain from '../Components/FAQMain'
import GoToTop from '../Components/GoToTop'

const FAQ = () => {
  return (
    <>
    <Nav/>
    <FAQMain/>
    <Footer/>
    <GoToTop/>
    </>
  )
}

export default FAQ
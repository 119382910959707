 export const table3Data = [
    {
      id: 1,
      category: "Additional Benefits",
      details: ["- Drs consultation for report review"],
    },
    {
      id: 2,
      category: "Serum Iron basic",
      details: [
        "- Total Iron binding capacity",
        "- Iron serum",
        "- Unsaturated Iron binding capacity",
        "- Transferrin saturation",
      ],
    },
    {
      id: 3,
      category: "Vitamin Profile",
      details: ["- Vitamin B12", "- Vitamin B9", "- Vitamin D (25-Hydroxy)"],
    },
    {
      id: 4,
      category: "Diabetes Screening",
      details: ["- HbA1C", "- Fasting sugar"],
    },
    {
      id: 5,
      category: "Complete blood count",
      details: [
        "- Absolute Lymphocyte Count",
        "- Absolute Neutrophil Count",
        "- Differential Leukocyte Count (includes 5 tests)",
        "- Red Blood Cell Count",
        "- Hb (Hemoglobin)",
        "- Platelet Count",
        "- Total Leukocyte Count",
        "- Absolute Basophil Count",
        "- Absolute Monocyte Count",
        "- Absolute Eosinophil Count",
        "- Hematocrit",
        "- Mean Corpuscular Volume",
        "- Mean Corpuscular Hemoglobin",
        "- Mean Corpuscular Hemoglobin Concentration",
        "- Mean Platelet Volume",
        "- PDW",
        "- RDW CV",
      ],
    },
    {
      id: 6,
      category: "Lipid Profile",
      details: ["- HbA1c"],
    },
    {
      id: 7,
      category: "Urine R/M",
      details: ["- HbA1c"],
    },
    {
      id: 8,
      category: "Liver Function Test",
      details: ["- HbA1c"],
    },
    {
      id: 9,
      category: "Thyroid Profile Total",
      details: ["- HbA1c"],
    },
    {
      id: 10,
      category: "KFT with Electrolytes",
      details: ["- HbA1c"],
    },
    {
      id: 11,
      category: "Microalbumin Creatinine",
      details: ["- HbA1c"],
    },
  ];

 export const table2Data = [
    {
      id: 1,
      category: "Additional Benefits",
      details: ["- Drs consultation for report review"],
    },
    {
      id: 2,
      category: "Thyroid profile",
      details: [
        "- Total Trilodothyronine (T3)",
        "- Total Thyroxine (T4)",
        "- Thyroid stimulating Hormone (TSH)",
      ],
    },
    {
      id: 3,
      category: "Lipid Profile",
      details: [
        "- Total Cholesterol",
        "- HDL Cholesterol-Direct",
        "- LDL Cholesterol-Direct",
        "- LDL/HDL Ratio",
        "- NON-HDL Cholesterol",
        "- TC/HDL Cholesterol Ratio",
        "- Triglycerides",
        "- VLDL Cholesterol",
        "- HDL/LDL Ratio",
      ],
    },
    {
      id: 4,
      category: "Liver Profile",
      details: [
        "- Alkaline Phosphatase",
        "- Bilirubin-Direct",
        "- Bilirubin-Total",
        "- Bilirubin-Indirect",
        "- Gamma Glutamyl Transferase",
        "- Protein-Total",
        "- Serum Albumin",
        "- Serum Globulin",
        "- SGOT (AST)",
        "- SGPT (ALT)",
        "- Serum Albumin/Globulin Ratio",
        "- SGOT/SGPT Ratio",
      ],
    },
    {
      id: 5,
      category: "Kidney Profile",
      details: [
        "- BUN/ Creatinine Ratio",
        "- Blood Urea Nitrogen",
        "- Calcium",
        "- Est. Glomerular filtration rate (eGFR)",
        "- Serum creatinine",
        "- Uric Acid",
        "- Urea (Calculated)",
        "- Urea/Creatinine Ratio",
      ],
    },
    {
      id: 6,
      category: "Iron Deficiency",
      details: [
        "- Total Iron Binding Capacity",
        "- Serum Iron",
        "- % Trasferrin Saturation",
        "- Unsaturated Iron-Binding Capacity",
      ],
    },
    {
      id: 7,
      category: "Electrolytes Profile",
      details: ["- Sodium", "- Chloride"],
    },
    {
      id: 8,
      category: "Diabetes Screen",
      details: ["- HbA1c", "- Average Blood Glucose"],
    },
    {
      id: 9,
      category: "Complete Blood Count",
      details: [
        "- Absolute Lymphocyte Count",
        "- Absolute Neutrophil Count",
        "- Differential Leukocyte (includes 5 tests)",
        "- Red Blood Cell Count",
        "- Hb (Hemoglobin)",
        "- Platelet Count",
        "- Total Leukocyte Count",
        "- Absolute Basophil Count",
        "- Absolute Monocyte Count",
        "- Absolute Eosinophil Count",
        "- Hematocrit",
        "- Mean Corpuscular Volume",
        "- Mean Corpuscular Hemoglobin",
        "- Mean Corpuscular Hemoglobin Concentration",
        "- Mean Platelet Volume",
        "- PDW",
        "- RDW CV",
      ],
    },
  ];
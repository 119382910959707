import React from 'react'
import Table1 from './Table1';
import Table2 from './Table2';
import Table3 from './Table3';
import HealthCareSectionBlock from './HealthCareSectionBlock';

const HealthCarePackageMain = ( {table3Data ,table2Data,isActive,editHeight,editPadding}) => {
  return (
    <section className=" bg-[#f9f9f9]	relative flex flex-col items-center py-[60px]
    ">
    <div className=" container w-full px-[15px] mx-auto">
        <div className=" flex flex-wrap mx-[-15px] ">
            <HealthCareSectionBlock/> 
        </div>
        <div className="flex flex-wrap mx-[-15px] justify-center">
            <Table1 isActive={isActive} editHeight={editHeight}   editPadding={editPadding}/>
            <Table2  table2Data={table2Data} isActive={isActive} editHeight={editHeight}/>
            <Table3 table3Data={table3Data} isActive={isActive} editHeight={editHeight}/>
        </div>	
    </div>	
</section>
  )
}

export default HealthCarePackageMain
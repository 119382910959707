import React from 'react'
import DoctorAppointmentForm from '../Components/subComponents/DoctorAppointmentForm'
import sectionImg from '../assets/images/section-img.png'
import appointmentImg from '../assets/images/book-your-appointment.webp'

const DoctorBooking = () => {
  return (
    <>
     <section className=' flex flex-col items-center md:h-[181px] h-[175px]  lg:py-[10px] md:pt-[20px]  justify-center text-center bg-[#F9F9F9]  '>
                        <h2 className=' text-[21px] md:text-[2rem] capitalize  font-medium text-[#2C2D3F] leading-[1.2] '>
                        We Are Always Ready to Help You & Your Family</h2>
                        <img src={sectionImg} alt="section" loading='lazy'/>                
    </section> 
    <section className='  lg:h-[767px] h-[1016px] pb-[160px] md:h-[1180px]  md:px-[15px] bg-[#F9F9F9] w-full flex items-center justify-center mx-auto'>
       <div className='lg:h-[596px] h-[910px] md:h-[1140px] md:mx-[48px] mx-[8px] lg:w-[1140px] md:w-[768px]  w-[98%] flex flex-col lg:flex-row justify-center 
       items-center  bg-[#F9F9F9]  shadow-[0px_0px_10px_#00000024] rounded-[5px]'>
                      <div className="  lg:w-[674px] md:w-[642px] md:h-[551px] md:px-[48px] px-[20px]  h-[324px] w-[249px]">
                              <div className=" relative mb-[20px] pb-[10px] leading-[27px]">
                      <h5 className=" text-[#2C2D3F] text-[1.25rem] leading-tight font-bold
                       before:absolute before:left-0 before:bottom-0 before:h-[2px] before:bg-[#000000] 
                       before:w-[150px]">Doctor Appointment Booking</h5>
                     
                  </div>
                  <div>
                       <img src={appointmentImg} alt="Book your Appointment" className='md:h-[500px]' loading='lazy'/>
                       </div>
                      
                      </div>
                      <div className="  px-[15px] lg:w-[475px] md:w-[596px] md:mx-2 md:h-[430px]  lg:h-[435px] h-[430px] w-[98%] md:mt-0 mt-[40px] " >
                          <DoctorAppointmentForm/>
                      </div>				
  </div>
</section>
    </>
  )
}

export default DoctorBooking
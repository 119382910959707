import MainImg from '../../assets/images/nervfit.jpg'
import Img1 from '../../assets/images/newsAngMedical/SectionImg1.webp'
import Img2 from '../../assets/images/newsAngMedical/SectionImg2.webp'
import Img3 from '../../assets/images/newsAngMedical/SectionImg3.webp'


export const postDetails = {
    img: MainImg,
    title: "Nervfit - The fitness tracking smart device from MyCLNQ",
    views: "3k",
    date: "JUNE 27, 2024",
    text: <>
        <p>27 June, 2024 Raipur Nervfit and MyCLNQ partner to expand technology enabled care in
            India Nervfit, a leading player in smart wearables technology, and MyCLNQ Health
            Singapore, a leading digital health solution provider, have announced their strategic
            collaboration to launch Technology Enabled Care (TEC) systems in India. The
            partnership, with an investment of approximately INR 100 crore over 3 years, will
            combine Nervfit's expertise in fitness tracking smart devices with MyCLNQ's advanced
            health monitoring capabilities to provide affordable and holistic solutions to meet the
            health and fitness goals of all Indians. </p>
        <p>Piyush Pathak, CEO of Nervfit sharing his views on
            the occasion said, “We are very optimistic and confident that our collaboration will
            empower our smart watch users with the advanced health monitoring features of
            MyCLNQ. This collaboration will provide Nervfit customers a digital platform to effectively
            manage their fitness needs through an easy and intuitive interface using AI and machine
            learning.” Bharat Maheshwari, CBO, MyCLNQ Health India further added, “Collaborating
            with Nervfit is an important milestone in our mission to advance healthcare access
            through innovative health technologies and solutions in the Indian market. Together, we
            are setting new standards for wearable smart devices that are designed to meet the
            diverse needs of our customers.” </p>
        <p>
            To mark this strategic alliance, both Nervfit and
            MyCLNQ are introducing exclusive offers for early stage customers. The group has
            invited its users and fans on its social media platforms, online marketplaces Amazon,Flipkart, AJIO and www.nervfit.com for exciting deals which will be launched very soon.
            Sanjay Kumar, CTO of Nervfit informed that in the near future Nervfit is going to launch
            more medical grade devices which will make it easier to collect health related information
            and use it in various ways. In this, the data will be viewed in a more detailed manner with
            the use of artificial intelligence.
        </p>
        <p>Farzana Siddhi, CTO, MyCLNQ Health added, “As India
            adopts these cutting edge technologies, this partnership will be a milestone in delivering
            efficient, scalable and patient-centric holistic healthcare at the national level.” During the
            announcement of the partnership, discussions also highlighted the importance of
            Technology Enabled Care (TEC) in India, as advancements in digital health solutions are
            rapidly revolutionizing the healthcare landscape. With innovations ranging from smart
            wearable devices to telemedicine service platforms, TEC is empowering both patients
            and healthcare providers. This shift to digital health not only increases access and
            convenience,but also improves the health outcomes of patients through real time
            monitoring and personalized care.</p>
        <p>e. Who is MyCLNQ MyCLNQ Health, a National
            award winning HealthTech company headquartered in Singapore, has operations in over
            eight countries. They specialise in connected healthcare solutions for treatment and care.
            They operate the comprehensive "MyCLNQ" ecosystem, an integrated platform to meet
            various family healthcare needs. The AI-powered mobile app focuses on telemedicine,
            mHealth, and eHealth services, aimed at providing cost-effective healthcare access in
            assisted settings.</p>
        <p>Visit
            <a href="https://www.nervfit.com/" className="text-reddish"
                target="_blank" rel="noopener noreferrer"> www.nervfit.com</a> and www.myclnq.co for more information.
        </p>

    </>
}


export const recentPost = [
    {
        id: 1,
        img: Img1,
        heading: "Change In Eye Colour And Why It Warrants Medical Attention",
        date: "Nov 9, 2023",
        link: "/articleEye",
        border: true
    },
    {
        id: 2,
        img: Img2,
        heading: "Treating Chronic Migraines: What To Do Next If Nothing Helps",
        date: "Oct 26, 2023",
        link: "/articleMigrane",
        border: true
    },
    {
        id: 3,
        img: Img3,
        heading: "5 Home Remedies To Ease Your Jaw Pain And Find Relief",
        date: "Oct 20, 2023",
        link: "/articleRemedies",
        border: false
    },
]
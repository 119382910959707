import React, { useState, useEffect } from 'react'
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import img1 from '../assets/images/Events/dataramsociety/img1.jpeg';
import img2 from '../assets/images/Events/dataramsociety/img2.jpeg';
import img3 from '../assets/images/Events/dataramsociety/img3.jpeg';


function Event1() {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);


    const slides = [
        {
            url: img1,
        },
        {
            url: img2,
        },
        {
            url: img3,
        },
    ];


    const prevSlide = () => {

        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };

    const nextSlide = () => {

        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };
    const onAnimationEnd = () => {
        setAnimate(false);
    };

    const transitionStyles = {
        transition: 'background-image 0.5s ease-in-out',
        animation: animate ? 'fadeInOut 0.5s ease-in-out' : 'none',
    };
    const bannerStyle = {
        backgroundImage: `url(${slides[currentIndex].url})`,
        ...transitionStyles,
    };


    const nextSlideWithAnimation = () => {

        setAnimate(true);
        setTimeout(() => {
            nextSlide();
        }, 500);
    };
    useEffect(() => {
        const autoplayTimer = setInterval(() => {
            nextSlideWithAnimation();
        }, 3000);
        return () => clearInterval(autoplayTimer);
        // eslint-disable-next-line
    }, [currentIndex]);


    return (
        <>
            <section className=' md:m-11 md:mb-11 m-2 mb-[220px] lg:h-[500px] md:h-[1601px] h-[1440px]  bg-backgroundAsh'>
            <div className='flex lg:flex-row flex-col lg:items-center  lg:justify-center items-stretch justify-stretch w-full h-[500px] ' >
                <div className='  md:w-[50%] w-[100%]  h-[500px] '>
                    <div className="lg:mx-[48px] lg:[24px] p-2 ">
                        <h3 className=" text-reddish  font-poplin text-[1.75rem] leading-9 " >Health Checkup in Data Ram society in Rohini -&nbsp;Delhi </h3>
                        <p className=" my-4 font-poplin  text-[#2C2D3F] text-[1rem]">MyCLNQ India organized a successful camp at Data Ram Society Rohini, Delhi. Over 60 patients were served, with 40+ enrolling in healthcare packages. The event highlighted the importance of accessible healthcare, catering to the community's needs. Through this initiative, MyCLNQ India demonstrated its commitment to improving healthcare accessibility and promoting wellness in the Rohini area of Delhi.</p>
                    </div>
                </div>
                <div className='  md:w-[50%] w-[100%] h-[500px] ' >
                    <div
                        style={bannerStyle}
                        onAnimationEnd={onAnimationEnd}
                        className=' md:w-[84%] w-[98%] md:left-[40px] left-[4px]  md:h-[485px] h-[500px] bg-center bg-cover transition-all duration-500 relative rounded-3xl '
                    >
                           {/* Left Arrow */}
                    <div className='absolute lg:top-[50%] md:top-[409px] top-[50%] left-0 md:left-[7px] -translate-x-0 
        translate-y-[-50%] lg:left-[0.75rem]  rounded-full w-[50px] h-[50px]
          text-white  cursor-pointer leading-[50px] inline-block'>
                        <HiMiniChevronLeft onClick={prevSlide} size={50} />
                    </div>
                    {/* Right Arrow */}
                    <div className=' absolute  lg:top-[50%] md:top-[409px] right-0   top-[50%]  w-[50px] h-[50px]
          -translate-x-0 translate-y-[-50%] md:right-[7px]  text-2xl leading-[50px] rounded-full
        text-white cursor-pointer  inline-block'>
                        <HiMiniChevronRight onClick={nextSlide} size={50} />
                    </div>
   
                    </div>
                </div>
            </div>
            </section>
            {/* Event 1 ends Here */}
            
        </>
    )
}

export default Event1
import React, { useState, useEffect } from 'react';
import { HiMiniChevronLeft, HiMiniChevronRight } from "react-icons/hi2";
import img1 from "../assets/images/Events/Booth/img1.jpg";
import img2 from "../assets/images/Events/Booth/img2.jpg";
import img3 from "../assets/images/Events/Booth/img3.jpg";
import img4 from "../assets/images/Events/Booth/img4.jpg";

function Event3() {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);


    const slides = [
        {
            url: img1,
        },
        {
            url: img2,
        },
        {
            url: img3,
        },
        {
            url: img4,
        },
        
    ];


    const prevSlide = () => {

        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };

    const nextSlide = () => {

        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
        setAnimate(true);
    };
    const onAnimationEnd = () => {
        setAnimate(false);
    };

    const transitionStyles = {
        transition: 'background-image 0.5s ease-in-out',
        animation: animate ? 'fadeInOut 0.5s ease-in-out' : 'none',
    };
    const bannerStyle = {
        backgroundImage: `url(${slides[currentIndex].url})`,
        ...transitionStyles,
    };


    const nextSlideWithAnimation = () => {

        setAnimate(true);
        setTimeout(() => {
            nextSlide();
        }, 500);
    };
    useEffect(() => {
        const autoplayTimer = setInterval(() => {
            nextSlideWithAnimation();
        }, 3000);
        return () => clearInterval(autoplayTimer);
        // eslint-disable-next-line
    }, [currentIndex]);


  return (
    <>
    <section className=' md:m-11 m-2  bg-backgroundAsh'>
            <div className='flex lg:flex-row flex-col'>
                <div className=' md:w-[50%] w-[100%] md:h-[500px] h-[700px]'>
                    <div className="lg:mx-[48px] lg:[24px] p-2">
                        <h3 className=" text-reddish font-poplin text-[1.75rem] leading-9">Free Health Checkup Camps At Remote Areas</h3>
                        <p className=" my-4 font-poplin  text-[#2C2D3F] text-[1rem]">MyCLNQ India has revolutionized healthcare accessibility through its innovative e-health Mitra and Virtual Consultation Centers, catering to the needs of over 200 patients across multiple cities. These digital platforms offer convenient avenues for individuals to seek medical advice and assistance remotely, breaking down barriers of distance and time. <br /> By leveraging technology, MyCLNQ India has transformed the healthcare landscape, ensuring that even those in remote areas can access quality medical care.  The Virtual Consultation Centers serve as hubs of expertise, connecting patients with healthcare professionals seamlessly. Furthermore, the Healthcare Booths established in various locations provide physical access points for consultations and support services. <br /> MyCLNQ India's commitment to reaching diverse communities underscores its dedication to democratizing healthcare, making it more inclusive and accessible to all, irrespective of geographical constraints</p>
                    </div>
                </div>
                <div className='  md:w-[50%] w-[100%] h-[500px] ' >
                    <div
                        style={bannerStyle}
                        onAnimationEnd={onAnimationEnd}
                        className='md:w-[84%] w-[98%] md:left-[40px] left-[4px]  md:h-[485px] h-[500px] bg-center bg-cover transition-all duration-500 relative rounded-3xl'
                    >
                           {/* Left Arrow */}
                    <div className='absolute  lg:top-[50%] md:top-[409px] top-[50%] left-0 md:left-[7px] -translate-x-0 
        translate-y-[-50%] lg:left-[0.75rem]  rounded-full w-[50px] h-[50px]
          text-white  cursor-pointer leading-[50px] inline-block'>
                        <HiMiniChevronLeft onClick={prevSlide} size={50} />
                    </div>
                    {/* Right Arrow */}
                    <div className=' absolute   lg:top-[50%] md:top-[409px]  md:right-[7px] right-[0px]  top-[50%]  w-[50px] h-[50px]
          -translate-x-0 translate-y-[-50%]  text-2xl leading-[50px] rounded-full
        text-white cursor-pointer  inline-block'>
                        <HiMiniChevronRight onClick={nextSlide} size={50} />
                    </div>
   
                    </div>
                </div>
            </div>
            </section>
    </>
  )
}

export default Event3
import React from 'react'
import bgImg from "../assets/images/fun-bg.webp"
import { factsList} from "./Lists/factsList"

const Facts = () => {

  return (
    <section className=' lg:h-[219px] xl:h-[195px] overflow-hidden  h-[880px] bg-cover bg-no-repeat 
    relative py-[70px] before:absolute before:h-full before:w-full before:bg-reddish 
    before:opacity-[0.8] before:top-0 before:left-0'     style={{ backgroundImage: `url(${bgImg})` }}>
        <div className=" absolute !text-[#fff] right-0 left-0 top-0 bottom-0 lg:mx-[230px]  flex justify-center items-center   px-[15px] ">
            <div className='flex lg:flex-row flex-col  justify-center items-center'>
                {factsList.map(({id,icon,counter,text})=>
                <div className='flex lg:flex-row flex-col justify-center items-center lg:w-[285px] lg:h-[30px] 
                h-[185px] w-[375px] px-[15px] mx-[35px] md:mx-0 ' key={id}>
                     <div className='relative group hover:bg-[#fff]  border-[2px] border-[#fff] 
                     min-h-[70px]  min-w-[70px] rounded-full flex justify-center items-center  duration-500'>
                        {icon}
                    </div>
                    <div className='md:pl-[14px] pl-0'>
                        <div className="flex justify-center items-center text-center">
                            <span className="text-[#fff] font-semibold text-[30px] block lg:mb-0 mb-[7px]">{counter}</span><span className="text-[#fff] font-semibold text-[30px] block lg:mb-0 mb-[7px]">+</span>
                        </div>
                        <p className='text-[#fff] text-[15px] text-center font-normal leading-6'>{text}</p>
                    </div>
                </div>)}
            </div>
		</div>
    </section>
  )
}

export default Facts
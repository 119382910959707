import React,{useContext, useState} from 'react'
import Button from './Button'
import DataContext from '../../hooks/DataContext';

const DoctorAppointmentForm = () => {

    const [formData, setFormData] = useState({
        input_name: '',
        input_tel: '',
        input_email: '',
        input_date: '',
        input_enquiry: ''
      });
      const [successMessage, setSuccessMessage] = useState('');

     const {doctorPackageState}=useContext(DataContext)
    const { packageName,packagePrice,packageNameIN }=doctorPackageState

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
      const validateEmail = (user_email) => {
        let re =/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(user_email).toLowerCase());
        };
        const handleSubmit = async(e) => {
            e.preventDefault();
            const isValidEmail = validateEmail(formData.input_email);
            console.log('Form submitted with data:', formData);
            let selectedDateTime = new Date(formData.input_date);

            let formattedDateTime = selectedDateTime.toLocaleString('en-US', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            });
            const { input_name, input_email, input_tel, input_enquiry } = formData;
            let params = {
                user_name:input_name,
                user_email: input_email,
                phone_number:input_tel,
                user_message:input_enquiry,
                packageName:packageNameIN,
                selected_datetime: formattedDateTime
                // "g-recaptcha-response": captchaToken,
              };

              if (isValidEmail) {
                console.log(params)
                // Fetch for packages
                // fetch("http://localhost:3000/1", {
                  try {
                    const response = await  fetch("https://myclnqapi.ssivixlab.com/mailDoctorBookingInida", {
                      method: "POST",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({...params}),
                    })
                    if (response.status === 200) {
                      setTimeout(() => {
                        setSuccessMessage('Request Successful');
                        // Reset the form data after submission    
                  setFormData({
                      input_name: '',
                      input_tel: '',
                      input_email: '',
                      input_date: '',
                      input_enquiry: ''
                    });
                        }, 1000);
                    } else {
                      // Handle other response statses (non-200)
                      console.error("Error: Unexpected status code -", response.status);
                    }
                    
                  } catch (error) {
                    alert('Please Try Again')
                    console.error("Error:", error);
                  }
              }
              else{
                alert("Please Enter a valid Email")
              }
              
           
          };
  return (
    <>
     <form className="  flex  flex-col  md:mx-[-10px] mx-[-8px]
     lg:w-[429px]  lg:h-[435px] md:h-[304px] md:w-[596px]  w-[98%] h-[334px]" method='POST' id='healthcarePackage'  onSubmit={(e)=>handleSubmit(e)}
     >
      <div className=" lg:pb-[10px] lg:h-[40px]  md:mb-[20px] mb-[0px] relative ">
     <h2 className='relative text-reddish lg:text-[1.4rem] md:text-[25px] text-[17px] font-bold leading-tight 
    capitalize mt-[1rem] mb-[0.25rem] text-left '>{packageName}</h2>
                                  <span className="absolute w-[150px] right-0 md:left-[3px] left-0 font-bold  lg:top-[74px]
                                   md:top-[82px] top-[63px] h-[2px] bg-reddish"></span>
         
         </div>
         <div className="  text-[1rem]  font-semibold lg:pt-[2rem] md:pt-0 text-[#888] leading-[24px] margin-top: 10px" id="packagePrice ">{packagePrice}</div>
 
                                     <div className="flex flex-wrap flex-col my-[20px]  ">
                                         <div className="px-[15px]">
                                             <div className="mb-[10px]">
                                                 <input type="text" className="contact-form-inputs quote-form-inputs"
                                                  name="input_name" id="user_nameContact" 
                                                  placeholder="Name *" required
                                                  value={formData.input_name}
                                                  onChange={handleChange}
                                                  />
                                             </div>
                                         </div>
                                       
                                         <div className="px-[15px]">
                                             <div className="mb-[10px]">
                                                 <input type="text" className='contact-form-inputs quote-form-inputs' pattern="[0-9]{8,10}" inputMode="numeric" name="input_tel" id="phone_numberContact"
                                                  placeholder="Contact Number *" required
                                                  value={formData.input_tel}
                                                  onChange={handleChange}
                                                  />
                                             </div>
                                         </div>
                                         <div className="px-[15px]">
                                             <div className="mb-[10px]">
                                                 <input type="email" className='contact-form-inputs quote-form-inputs' name="input_email" id="user_emailContact" placeholder="Email *" required
                                                  value={formData.input_email}
                                                  onChange={handleChange}
                                                 />
                                             </div>
                                         </div>
                                         <div className="px-[15px]">
                                             <div className="mb-[10px] ">
                                             <label htmlFor="input_date" 
                                              className=" inline-block mb-[0.5rem] text-[0.85rem] font-bold text-[#888] leading-[24px]">Select Date and Time:</label>
								
                                                 <input type="datetime-local" className='contact-form-inputs quote-form-inputs lowercase' name="input_date" id="user_date" placeholder="Email *" required  min={new Date().toISOString().slice(0, 16)}
                                                   value={formData.input_date}
                                                   onChange={handleChange}
                                                 
                                                 />
                                             </div>
                                         </div>
                                         <div className="px-[15px]">
                                             <div className="mb-[10px]">
                                                 <input type="text" className="contact-form-inputs quote-form-inputs"
                                                  name="input_enquiry" id="user_enquiry" placeholder="Special Note..." required
                                                  value={formData.input_enquiry}
                                                  onChange={handleChange}
                                                  />
                                             </div>
                                         </div>
                                         <div id="resultContact" className=" leading-[24px] font-bold  text-center text-[14px] text-[#888] ">
                                            {successMessage}
                                         </div>
                                         <div className="px-[15px]">
                                             <div className="my-2 flex justify-center items-center">
                                                  <Button text={"Submit"}/>
                                             </div>
                                         </div>
                                     </div>
                                 </form>
    </>
  )
}

export default DoctorAppointmentForm
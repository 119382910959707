import React, { useEffect } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import AboutMain from "../Components/AboutMain";
import Facts from "../Components/Facts";
import Management from "../Components/Management";
import GoToTop from "../Components/GoToTop";
import { useParams } from "react-router-dom";
import sectionImg from '../assets/images/section-img.png'


const About = () => {
  return (
    <>
      <Nav />
      {/* <AboutMain/> */}
      {/* <Facts/> */}
      <Management />
      <Footer />
      <GoToTop />
    </>
  );
};

export default About;

import React from 'react'
import Nav from '../Components/Nav'
import DoctorBooking from '../Components/DoctorBooking'
import Footer from '../Components/Footer'
import GoToTop from '../Components/GoToTop'

const BookDoctorAppointment = () => {
  return (
     <>
     <Nav/>
     <DoctorBooking/>
     <Footer/>
     <GoToTop/>
     </>
  )
}

export default BookDoctorAppointment
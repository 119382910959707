import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import ArticleMain from '../Components/ArticleMain'
import { recentPost, postDetails} from "../Components/Lists/articleAllergy"
import GoToTop from '../Components/GoToTop'

const ArticleAllergy = () => {
  return (
    <>
      <Nav/>
      <ArticleMain recentPost={recentPost} postDetails={postDetails}/>
      <Footer/>
      <GoToTop/>
    </>
  )
}

export default ArticleAllergy